.landing {
    font-family: 'WSF';
    font-size: 5vw;
    color: white;
}

.landingLogo {
    width: 40vw;
    height: 40vw;

    pointer-events: none;

    margin-top: 1vh;
}

@media (prefers-reduced-motion: no-preference) {
    .landingLogo {
        animation: landingLogoSpin infinite 20s linear;
    }
}

@keyframes landingLogoSpin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.accentLine {
    position: absolute;
    height: 48vw;

    border-left: 1vw solid #03a4e8;
}

.leftLine {
    margin-left: 9vw;
}

.rightLine {
    margin: 55vw 0 0 91vw;
}

.landingWelcomeHeaderText {
    text-align: right;
    font-family: "WSFItalic";
    font-size: 6vw;

    margin: 0 10vw 2vw 0;
}

.landingHeaderText {
    font-size: 16.75vw;
    text-align: right;
    font-family: "WSFItalic";
    line-height: 12.75vw;

    margin: 0 10vw 0 0;
}

.landingDescriptionText {
    text-align: left;
    font-weight: 900;

    margin: 10vw 10vw 0 10vw;
}